import { createElementVNode as _createElementVNode, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "the-header",
  "data-e2e": "global-header"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "visually-hidden" }

import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';

    import { useI18next } from '@composables/i18next';
    import LanguageSelector from '@components/LanguageSelector.vue';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const h1 = ref(null);
    const { vT } = useI18next('global');

    const removeLanguageSelector = ['faq'];

    onMounted(() => {
        // Focus the h1 element to force screen readers back to the top after a navigation.
        // This will ensure screen readers will read the page again.
        // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
        const router = useRouter();
        router.afterEach(() => {
            if (h1.value) {
                h1.value.focus();
            }
        });
    });

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      _createElementVNode("img", {
        src: _ctx.$i18nAsset('img/starbucks-rewards-logo.png'),
        alt: ""
      }, null, 8, _hoisted_2),
      _withDirectives(_createElementVNode("span", _hoisted_3, null, 512), [
        [_unref(vT), 'starbucksRewards']
      ])
    ], 512),
    (!removeLanguageSelector.includes(_ctx.$store.state.ui.pageName))
      ? (_openBlock(), _createBlock(LanguageSelector, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

}