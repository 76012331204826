
    import { useI18next } from '@composables/i18next';

    export default {
        name: 'SiteErrors',

        setup () {
            const { t, vT } = useI18next(['error']);
            return { t, vT };
        },
    };
