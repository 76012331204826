import axios from 'axios';
import { every } from 'lodash';
import api from '../../api';

const getDefaultState = () => ({
    loaded: false,
    challenges: {},
});

const state = getDefaultState();

const getters = {
    areAllChallengesCompleted (state, getters) {
        return every(state.challenges, challenge => challenge.results.completed);
    },

    isChallengeClaimable: (state) => (challengeId) =>
        state.challenges[challengeId].results.progress ===
        state.challenges[challengeId].properties.target,

    isChallengeComplete: (state) => (challengeId) =>
        state.challenges[challengeId].results.completed,

    userChallengeIds (state) {
        return Object.keys(state.challenges);
    },

    userChallenges (state) {
        return state.challenges;
    },
};

const mutations = {
    resetChallenges (state) {
        state = getDefaultState();
    },

    updateChallenges (state, { achievements }) {
        for (const achievement of achievements) {
            state.challenges[achievement.achievementName] = { ...achievement };
        }

        state.loaded = true;
    },

    progressChallenge (state, { challengeId }) {
        if (challengeId in state.challenges) {
            const { progress } = state.challenges[challengeId].results;
            const { target } = state.challenges[challengeId].properties;
            // use Math.min to prevent us from progressing a challenge past the target
            state.challenges[challengeId].results.progress = Math.min(progress + 1, target);
        }
    },

    completeChallenge (state, { challengeId }) {
        state.challenges[challengeId].results.completed = true;
    },
};

const actions = {
    async loadChallenges ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/challenges/checkAll`);
            const achievements = response.data;

            commit('updateChallenges', { achievements });
        }
        catch (error) {
            console.error('could not load challenges', error);

            throw error;
        }
    },

    async claimChallenge ({ commit }, { challengeId }) {
        try {
            const response = await axios.post(`${api.base}/challenges/claim`, { challengeId });
            const { results } = response.data;

            commit('completeChallenge', { challengeId });

            return results;
        }
        catch (error) {
            console.error('could not claim challenge', error);

            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
