import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ShareIcon = _resolveComponent("ShareIcon")

  return ($data.isShareSupported)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "button icon",
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.handleShareButtonClick && $options.handleShareButtonClick(...args)))
      }, [
        _createVNode(_component_ShareIcon),
        _createElementVNode("span", {
          class: "visually-hidden",
          innerHTML: $setup.t('social:cta')
        }, null, 8, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}