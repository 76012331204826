
    import { mapActions, mapGetters } from 'vuex';

    import { useI18next } from '@composables/i18next';
    import IconClose from '@components/IconClose.vue';
    import SocialShare from '@components/SocialShare.vue';
    import { sleep } from '@public/js/utils/sleep';

    import BaseModal from './BaseModal';

    const tierMap = {
        1: 10,
        2: 15,
        3: 25,
        4: 50,
        5: 75,
        6: 100,
        7: 200,
    };

    export default {
        name: 'TierModal',

        components: {
            IconClose,
            SocialShare,
        },

        extends: BaseModal,

        props: {
            tier: {
                type: Number,
                required: true,
            },
        },

        setup () {
            const { t, vT } = useI18next(['tier_modal']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'tier-modal',

                claimStep: 'ready',
                showClaimButton: false,
            };
        },

        computed: {
            ...mapGetters({
                isChallengeClaimable: 'challenges/isChallengeClaimable',
                isChallengeComplete: 'challenges/isChallengeComplete',
            }),

            isClaimable () {
                return this.isChallengeClaimable(`tier${this.tier}`);
            },

            isComplete () {
                return this.isChallengeComplete(`tier${this.tier}`);
            },

            isUnlocked () {
                return this.isClaimable || this.isComplete;
            },

            starAmountImageSource () {
                return require(`../../img/stars-${tierMap[this.tier]}.svg`);
            },
        },

        beforeMount () {
            this.showClaimButton = this.isClaimable;
        },

        methods: {
            ...mapActions({
                claimChallenge: 'challenges/claimChallenge',
            }),

            async doClaim () {
                this.claimStep = 'claiming';

                // Wait for animation
                await sleep(500);

                await this.claimChallenge({ challengeId: `tier${this.tier}` });
                this.claimStep = 'complete';
            },
        },
    };
