
    import { useI18next } from '@composables/i18next';
    import IconClose from '@components/IconClose.vue';
    import BaseModal from './BaseModal';

    export default {

        name: 'ReminderModal',
        i18nOptions: { namespaces: ['reminder_modal'] },

        components: {
            IconClose,
        },

        extends: BaseModal,

        props: {
            bonusStars: {
                type: Number,
                required: true,
            },
        },
        setup () {
            const { t, vT } = useI18next(['reminder_modal']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'reminder_modal',
            };
        },
    };
