
    import { useI18next } from '@composables/i18next';
    import IconClose from '@components/IconClose.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'InstructionsModal',

        components: {
            IconClose,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['instructions_modal']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'instructions-modal',
            };
        },
    };
