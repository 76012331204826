import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "the-footer" }
const _hoisted_2 = { class: "footer-disclaimers small-only" }
const _hoisted_3 = {
  key: 0,
  class: "legal oamoe-link"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "footer-links" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "footer-disclaimers large-only" }
const _hoisted_12 = {
  key: 0,
  class: "legal oamoe-link"
}
const _hoisted_13 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["src"]

import { computed } from 'vue';
    import { useStore } from 'vuex';

    import { useI18next } from '../composables/i18next';
    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();
    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const inPhaseLaunched = computed(() => store.state.app.phase == 'launched');

    async function signOut () {
        try {
            window._analytics.logout();
        }
        catch (error) {
            console.error('[GDS] Logout unsuccessful');
        }

        await store.dispatch('profile/logOut');
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), { key: 'disclaimer' }]
      ]),
      (inPhaseLaunched.value)
        ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_3, null, 512)), [
            [_unref(vT), { key: 'oamoe_text', oamoeUrl: '#/oamoe' }]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), { key: 'disclaimer_2', tb2Url: _unref(store).state.app.tb2URL }]
      ])
    ]),
    _createElementVNode("img", {
      class: "siren-logo",
      src: _ctx.$i18nAsset('img/siren-logo.png'),
      alt: ""
    }, null, 8, _hoisted_4),
    _createElementVNode("nav", _hoisted_5, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:rules_url', { tb2Url: _unref(store).state.app.tb2URL }),
            target: "_blank"
          }, null, 8, _hoisted_6), [
            [_unref(vT), 'links:rules_copy']
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "#/faq",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t('links:faq_copy')), 1)
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:privacy_url'),
            target: "_blank"
          }, null, 8, _hoisted_7), [
            [_unref(vT), 'links:privacy_copy']
          ])
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:terms_url'),
            target: "_blank"
          }, null, 8, _hoisted_8), [
            [_unref(vT), 'links:terms_copy']
          ])
        ]),
        (_unref(store).getters['profile/loggedIn'])
          ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _withDirectives(_createElementVNode("a", {
                href: _unref(store).state.app.sbuxLogoutURL,
                "data-e2e": "footer-logged-in",
                onClick: signOut,
                onKeyup: _withKeys(signOut, ["enter"])
              }, null, 40, _hoisted_10), [
                [_unref(vT), 'links:sign_out_copy']
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), { key: 'disclaimer' }]
      ]),
      (inPhaseLaunched.value && _unref(store).getters['profile/loggedIn'])
        ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_12, null, 512)), [
            [_unref(vT), { key: 'oamoe_text', oamoeUrl: '#/oamoe' }]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_unref(vT), { key: 'disclaimer_2', tb2Url: _unref(store).state.app.tb2URL }]
      ])
    ]),
    (_unref(store).state.app.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_ctx.$t('testNamespace:testKey'))
          }, null, 8, _hoisted_14),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_15)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}